import React from "react";
import { graphql } from "gatsby";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import EventIcon from "@material-ui/icons/Event";
import _classes from "../styles/main.module.css";
import Cards from "../components/cards";
import Contact from "../components/contact";
import Customers from "../components/customers";
import Demo from "../components/demo";
import Faq from "../components/faq";
import Features from "../components/features-2";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Spacer from "../components/spacer";
import { getImageUrl } from "../utils";
import { scheduleDiscoveryCall } from "../utils/calendly";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "grid",
    alignItems: "center",
    gridGap: "50px 100px",
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "1fr 1fr",
    },
  },
  column1: {
    order: 2,
    [theme.breakpoints.up("md")]: {
      order: 1,
    },
  },
  column2: {
    order: 1,
    [theme.breakpoints.up("md")]: {
      order: 2,
    },
  },
  media: {
    width: "100%",
    height: "100%",
    maxWidth: "100%",
    maxHeight: 360,
    objectFit: "contain",
    outline: 0,
  },
  heading: {
    color: "#28315E",
    fontSize: 24,
    fontWeight: 600,
    marginTop: 0,
    [theme.breakpoints.up("md")]: {
      fontSize: 32,
      textAlign: "center",
    },
  },
  heading2: {
    color: "#757A96",
    fontSize: 14,
    fontWeight: "normal",
    [theme.breakpoints.up("md")]: {
      fontSize: 16,
      textAlign: "center",
    },
  },
  line1: {
    fontSize: 18,
    display: "block",
    marginBottom: 6,
  },
  line2: {
    fontSize: 22,
    fontWeight: 600,
    whiteSpace: "pre-wrap",
  },
  box1: {
    background: "linear-gradient(354.37deg, #2F43AF 11.74%, #546FFF 88.26%)",
    boxShadow: "0px 4px 22px rgba(0, 0, 0, 0.16)",
    color: "#fff",
  },
  box2: {
    [theme.breakpoints.down("xs")]: {
      display: "grid",
    },
  },
  background1: {
    backgroundImage:
      "linear-gradient(to bottom, #fff 40%, #EDEEF4 40%, #EDEEF4 80%, #fff 80%)",
  },
  demo: {
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
    },
  },
  box4: {
    padding: "50px 0px",
    textAlign: "center",
    display: "grid",
    justifyItems: "center",
    gridGap: "50px 100px",
    [theme.breakpoints.up("lg")]: {
      gridAutoFlow: "column",
      textAlign: "left",
    },
  },
  heroHeading: {
    textAlign: "left",
    fontSize: 28,
    lineHeight: "1.2",
    marginBottom: 20,
    [theme.breakpoints.up("lg")]: {
      fontSize: 44,
    },
  },
  heroHeading2: {
    textAlign: "left",
    fontSize: 16,
    margin: 0,
    [theme.breakpoints.up("lg")]: {
      fontSize: 24,
    },
  },
}));

const Product = ({ data }) => {
  const classes = useStyles();

  const pageData = data.markdownRemark.frontmatter.data;

  return (
    <Layout>
      <SEO title={pageData.seoTitle} description={pageData.seoDescription} />

      <Spacer h={100} />

      <Container>
        <div style={{ overflow: "hidden" }}>
          <div>
            <div className={classes.row}>
              <div className={classes.column1}>
                <div>
                  <p className={`${classes.heading} ${classes.heroHeading}`}>
                    {pageData.hero.heading}
                  </p>
                  <p
                    className={`${classes.heading2} ${classes.heroHeading2}`}
                    style={{ color: "#757A96" }}
                  >
                    {pageData.hero.heading2}
                  </p>
                  <div style={{ margin: "30px -8px 0" }}>
                    <button
                      className={clsx(
                        _classes.button,
                        _classes.button_outlined
                      )}
                      style={{ margin: 8 }}
                      onClick={scheduleDiscoveryCall}
                    >
                      Request a Demo
                    </button>
                    <a
                      href={pageData.hero.cta_2.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={_classes.button}
                      style={{ margin: 8 }}
                    >
                      {pageData.hero.cta_2.text}
                    </a>
                  </div>

                  <p
                    style={{
                      fontSize: 12,
                      color: "#99A3B4",
                      margin: "10px 0 0",
                    }}
                  >
                    {pageData.hero.cta_caption}
                  </p>
                </div>
              </div>

              <div className={classes.column2}>
                {/([/|.|\w|\s|-])*\.(?:jpeg|jpg|gif|png|webp|svg)/gi.test(
                  pageData.hero.image
                ) ? (
                  <img
                    className={`${classes.media} lazyload`}
                    // imgix does not transformation for gif and webp files
                    data-src={
                      pageData.hero.image +
                      (/([/|.|\w|\s|-])*\.(?:gif|webp)/gi.test(
                        pageData.hero.image
                      )
                        ? ""
                        : "?w=800")
                    }
                    alt=""
                  />
                ) : (
                  <video
                    className={`${classes.media} lazyload`}
                    data-src={`${pageData.hero.image}?w=800`}
                    autoPlay
                    muted
                    loop
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Spacer h={120} />

      <section style={{ background: "#EDEEF4" }}>
        <Container>
          <div style={{ padding: "60px 0" }}>
            <Features features={pageData.features.cards} iconHeight={60} />
          </div>
        </Container>
      </section>

      <Container>
        <div id="demo" style={{ height: 100 }} />

        <div className={classes.demo}>
          <h1 className={classes.heading}>{pageData.try_product.heading}</h1>
          <h2
            className={classes.heading2}
            style={{ display: "inline-block", maxWidth: 700, margin: 0 }}
          >
            {pageData.try_product.heading2}
          </h2>
        </div>

        <Spacer h={60} />
        <Demo
          demo={pageData.demo}
          getStartedLink={pageData.getStartedLink}
          upload
          uploadUrl="https://customer.nanonets.com/invoice-digitization/predict"
        />
      </Container>

      <Spacer h={120} />

      <div className={classes.box1}>
        <Container>
          <div className={classes.box4}>
            <div>
              <img
                src="/media/invoice-capture-ai.png"
                alt=""
                style={{
                  width: "100%",
                  maxWidth: 400,
                  maxHeight: 200,
                  objectFit: "contain",
                }}
              />
            </div>
            <div style={{ maxWidth: 500 }}>
              <h1
                className={classes.heading}
                style={{
                  textAlign: "inherit",
                  color: "#fff",
                  fontWeight: 600,
                  margin: 0,
                }}
              >
                {pageData.cta_fold.heading}
              </h1>
              <div style={{ margin: "30px -8px 0" }}>
                <button
                  onClick={scheduleDiscoveryCall}
                  className={clsx(_classes.button, _classes.button_outlined)}
                  style={{
                    margin: 8,
                    color: "#fff",
                    border: "1px solid #fff",
                  }}
                >
                  Request a Demo
                </button>
                <a
                  href={pageData.cta_fold.cta_2.link}
                  className={clsx(_classes.button, _classes.button_outlined)}
                  style={{
                    margin: 8,
                    color: "#546fff",
                    background: "#fff",
                    border: 0,
                  }}
                >
                  {pageData.cta_fold.cta_2.text}
                </a>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <Spacer h={100} />

      <Container>
        <div className={classes.row}>
          <div className={classes.column1}>
            <img
              className={`${classes.media} lazyload`}
              data-src={getImageUrl({ url: pageData.fold_3.image, width: 800 })}
              alt=""
            />
          </div>
          <div className={classes.column2} style={{ maxWidth: 600 }}>
            <p className={classes.heading} style={{ textAlign: "left" }}>
              {pageData.fold_3.heading}
            </p>
            <p className={classes.heading2} style={{ textAlign: "left" }}>
              {pageData.fold_3.heading2}
            </p>
            <div style={{ marginTop: 30 }}>
              <button
                onClick={scheduleDiscoveryCall}
                className={_classes.button}
              >
                <span>Request a Demo</span>
                <EventIcon style={{ marginLeft: 10 }} />
              </button>
            </div>
          </div>
        </div>
      </Container>

      <Spacer h={100} />

      <section id="case-studies">
        <Container>
          <div>
            <h1 className={classes.heading}>Case Studies</h1>
            <h2 className={classes.heading2} style={{ margin: 0 }}>
              {pageData.case_studies.heading}
            </h2>
          </div>
          <div style={{ height: "3rem" }} />
          {pageData.case_studies.slides && (
            <Cards cards={pageData.case_studies.slides} />
          )}
        </Container>
      </section>

      {/* {
        pageData.use_cases.visible
        && (
          <section>
            <Spacer h={100} />

            <Container>
              <div>
                <h1 className={classes.heading}>Applicable across industries</h1>
              </div>
              <div style={{ height: '3rem' }} />
              <Cards cards={pageData.use_cases.slides} cardType={2} />
            </Container>
          </section>
        )
      } */}

      <Spacer h={100} />

      <Container>
        <Faq faq={pageData.faq} />
      </Container>

      <div style={{ marginTop: 128 }}></div>

      <Container>
        <Customers />
      </Container>

      <div style={{ marginTop: 320 }}></div>

      <Contact />
    </Layout>
  );
};

export default Product;

export const query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        data {
          seoTitle
          seoDescription
          getStartedLink
          hero {
            heading
            heading2
            cta_caption
            image
            cta_2 {
              text
              link
            }
          }
          features {
            cards {
              title
              summary
              icon
            }
          }
          fold_3 {
            image
            heading
            heading2
          }
          try_product {
            heading
            heading2
            cta_caption
          }
          demo {
            input
            output {
              result
            }
            demo_type
            output_type
          }
          cta_fold {
            heading
            cta_2 {
              text
              link
            }
          }
          use_cases {
            slides {
              title
              summary
              poster
              href
            }
          }
          case_studies {
            heading
            slides {
              title
              summary
              poster
              href
            }
          }
          faq {
            answer
            question
          }
        }
      }
    }
  }
`;
