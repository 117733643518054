import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import Content from "./content";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "grid",
    gridGap: "50px 100px",
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "1fr 1fr",
    },
  },
  col: {
    flex: "1 1 320px",
    minWidth: "50%",
  },
  heading: {
    color: "#28315E",
    fontSize: 24,
    fontWeight: 600,
    marginTop: 0,
    marginBottom: 64,
    [theme.breakpoints.up("md")]: {
      fontSize: 32,
      textAlign: "center",
    },
  },
  question: {
    marginTop: 0,
    fontSize: 16,
    color: "#546fff",
    fontWeight: 600,
    wordBreak: "break-word",
    [theme.breakpoints.up("md")]: {
      fontSize: 20,
    },
  },
  answer: {
    margin: 0,
    fontSize: 14,
    wordBreak: "break-word",
    [theme.breakpoints.up("md")]: {
      fontSize: 16,
    },
  },
}));

function Faq({ faq }) {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { page: { eq: "faq" } }) {
        frontmatter {
          faq {
            question
            answer
          }
        }
      }
    }
  `);

  const _faq = data.markdownRemark.frontmatter.faq;

  faq = faq || _faq;

  if (!faq) return null;
  if (faq.length === 0) return null;

  return (
    <div>
      <h1 className={classes.heading}>Frequently Asked Questions</h1>
      <div className={classes.row}>
        {faq.map((item, index) => (
          <div key={index} className={classes.col}>
            <p className={classes.question}>{item.question}</p>
            <div className={classes.answer}>
              <Content data={item.answer} />
            </div>
          </div>
        ))}
        <div className={classes.col} />
      </div>
    </div>
  );
}

export default Faq;
